<template>
	<form method="post" @submit.prevent="submitForm">
		<section class="form-wrapper">
		
			<div ref="formFields" class="form-inputs">
				<div class="input-field">
					<label for="namn">namn</label>
					<input v-model="form.name" class="input-item" name="name" type="text" placeholder=""/>
				</div>
				<div class="input-field">
					<label for="email">e-post</label>
					<input v-model="form.email" class="input-item" name="email" type="email" required placeholder=""/>
				</div>
				<div class="input-field">
					<label for="phone">telefon</label>
					<input v-model="form.phone" class="input-item" name="phone" type="text" placeholder=""/>
				</div>
				<div class="input-field big">
					<label for="message">meddelande</label>
					<textarea v-model="form.messageText" class="input-item" name="message" placeholder=""/>
				</div>
			</div>
			<div class="form-button">
				<div id="loading-gif" ref="loadingGif">
					<img src="../assets/images/loading-bars.gif" alt="Loading...">
				</div>
				<input ref="formSubmitButton" class="btn" type="submit" value="skicka"/>
			</div>
		</section>
	</form>
</template>
<script>
import axios from 'axios'
export default {
	name: 'SubscriptionForm',
	props: {
		buttonItem: {
			type: Object,
			required: false,
			default: function () {
				return {
					url: '',
					title: '',
				}
			},
		},
		bgColor: {
			type: String,
			required: true,
		},
		textColor: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			form: {
				name: '',
				email: '',
				phone: '',
				messageText: '',
				subject: 'NW Padel Abonnemang',
			},
		}
	},
	methods: {
		async submitForm() {
			const FormData = require('form-data')
			let submitButton = this.$refs.formSubmitButton
			let formFields = this.$refs.formFields
			let loadingGif = this.$refs.loadingGif

			const formData = new FormData()
			formData.append('name', this.form.name)
			formData.append('email', this.form.email)
			formData.append('phone', this.form.phone)
			formData.append('messageText', this.form.messageText)
			formData.append('subject', this.form.subject)

			loadingGif.classList.add('active')
			formFields.classList.add('inactive')
			submitButton.style.pointerEvents = 'none'

			const res = await axios
				.post('https://api2.nordicwellness.se/v2/basicmailservice/post', formData)
				.then((res) => {
					submitButton.value = 'Tack för ditt mail!'
					submitButton.classList.add('success')
				})
				.catch((err) => {
					submitButton.value = 'Något gick fel'
					formFields.classList.remove('inactive')
					submitButton.style.pointerEvents = 'unset'
					submitButton.classList.add('error')
				})
				.finally(() => {
					loadingGif.classList.remove('active')
				})
		},
	},
}
</script>
<style scoped lang="scss">
form {
	display: contents;
}
textarea {
	resize: none;
}
.form-wrapper {
	padding: 0;
	display: flex;
	width: 100%;
	height: 361px;
	flex-flow: column wrap;
	align-content: flex-start;
	justify-content: flex-end;
	margin: 0 -15px;
	@include max-width(small) {
		height: auto;
	}

	.form-inputs {
		display: flex;
		flex-flow: column wrap;
		width: 100%;
		padding-bottom: 15px;
		@include max-width(small) {
			flex-flow: column nowrap;
		}
	}

	label {
		@include paragraph-small;
		font-weight: bold;
		text-transform: uppercase;
		margin: 0;
	}
	.input-field {
		padding: 0 15px;
		flex-basis: 33.333%;
		height: 100%;
		width: 50%;
		display: flex;
		flex-flow: column nowrap;
		.input-item {
			padding-left: 5px;
			width: 100%;
			height: 100%;
			border-radius: 3px;
			border: solid 1px #555;
			background-color: #fff;

			margin-top: 12px;

			text-anchor: start;
		}
		@include max-width(small) {
			padding: 0;
			.input-item {
				margin-top: 0;
				margin-bottom: 12px;
			}
		}
		&.big {
			flex-basis: 100%;
			.input-item {
				padding-top: 5px;
			}
		}

		@include max-width(small) {
			width: 100%;
			flex-basis: 100%;
			.input-item {
				height: 48px;
			}
			&.big {
				.input-item {
					height: 232px;
				}
			}
		}
	}
	.form-button {
		width: 100%;
		padding: 15px;

		display: flex;
		justify-content: flex-end;
		.btn {
			@include paragraph-small;
			font-weight: bold;
			color: #fff;
			height: 40px;
			width: 100%;
			padding: 0.5px 35px;
			border-radius: 25px;
			border: solid 2px #fff;
			background-color: transparent;
			text-transform: uppercase;

			transition-duration: 0.4s;
			transition-timing-function: ease-out;

			&.success {
				border-color: $color-green;
			}
			&.error {
				border-color: $color-red;
			}
		}
		@include max-width(small) {
			padding: 0;
		}
	}

	#loading-gif {
		position: absolute;
		display: none;
		img {
			height: 40px;
			position: relative;
			right: 15px;
		}

		&.active {
			display: unset;
		}
	}
}

.inactive {
	opacity: 0.5;
	pointer-events: none;
}
</style>
